<template>
  <div class="login-wrap">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRule" label-position="left" label-width="0px" class="login-container">
      <h2 class="title">
        {{ sysName }}
      </h2>
      <el-form-item prop="userName">
        <el-input v-model.trim="loginForm.userName" type="text" auto-complete="off" placeholder="账号" />
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model.trim="loginForm.password" type="password" auto-complete="off" placeholder="密码" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" style="width:100%;" :loading="$store.state.isLoading" @click.native.prevent="doLogin">
          登录
        </el-button>
        <el-button id="captcha-button">
          登录
        </el-button>
      </el-form-item>
    </el-form>
    <div id="captcha-element" />
  </div>
</template>

<script>
import util from '@/common/js/util'
export default {
  inject: ['sysName'],
  props: {
    // 使用简单验证码还是复杂验证码
    simple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
      // 登录验证
      captchaRefreshTime: 0,
      captcha: null,
      loginForm: {
        userName: '',
        password: ''
      },
      loginRule: {
        userName: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  beforeMount() {
    this.initCaptcha()
  },
  methods: {
    doLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          const refreshButton = document.getElementById('aliyunCaptcha-btn-refresh')
          if (refreshButton && new Date().getTime() - this.captchaRefreshTime > 60000) {
            refreshButton.click()// 刷新验证码以防过期
          }
          document.getElementById('captcha-button').click()
        }
      })
    },
    async captchaVerifyCallback(captchaVerifyParam) {
      const params = {
        name: this.loginForm.userName,
        password: this.loginForm.password,
        captchaParam: captchaVerifyParam
      }
      const result = await this.$post('/api/login', params).catch(() => {})
      const verifyResult = {
        captchaResult: true, // 验证码验证是否通过，boolean类型，必选
        bizResult: null, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
      }
      if (!result || !result.data) {
        verifyResult.captchaResult = false
        return verifyResult
      }
      util.saveToSession('account', JSON.stringify(result.data))
      setTimeout(() => {
        this.$router.push('/admin/index')
      }, 1000)
      return verifyResult
    },
    // 验证通过后调用
    onBizResultCallback() {
    },
    getInstance(instance) {
      this.captcha = instance
    },
    initCaptcha() {
      const mask = document.getElementById('aliyunCaptcha-mask')
      if (mask) {
        mask.remove()
      }
      const popup = document.getElementById('aliyunCaptcha-window-popup')
      if (popup) {
        popup.remove()
      }
      // eslint-disable-next-line no-undef
      initAliyunCaptcha({
        SceneId: '1pr0hbwc', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
        prefix: '1uvvp4', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
        mode: 'popup', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
        element: '#captcha-element', // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
        button: '#captcha-button', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
        captchaVerifyCallback: this.captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
        onBizResultCallback: this.onBizResultCallback, // 业务请求结果回调函数，无需修改
        getInstance: this.getInstance, // 绑定验证码实例函数，无需修改
        language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
      })
      this.captchaRefreshTime = new Date().getTime()
    },
  }
}
</script>
<style lang="scss" scoped>
#captcha-button {
  display: none;
}
.login-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #57aefe;
  background: url(../assets/bg.jpg) no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;

  .login-container {
    /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    background-clip: padding-box;
    margin: 130px auto;
    width: 350px;
    padding: 35px 35px 15px 35px;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
    .title {
      margin: 0px auto 40px auto;
      text-align: center;
      color: #505458;
    }
  }
 }
 @media only screen and (max-width: 380px) {
   .login-wrap{
     .login-container {
       width: 250px;
     }
   }
 }
</style>
